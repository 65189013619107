<script>
	import { onMount } from 'svelte';
	
	let tally = 0;
	let storeId = parseInt($$props.storeId);
	let CART = [];
	const bottom = document.getElementById('bottom_tally') || false;

	function readCart() {
		CART = localStorage.cart ? JSON.parse(localStorage.cart) || [] : [];
	}

	function updateTally() {
		readCart();
		if (!CART || !CART.length) { // if no cart, tally should be 0
			tally = 0;
		} else { // reduce the cart to calculate the tally
			tally = CART.reduce(function (accumulator, obj) {
				// PROBLEM!!!!!!!!
				return obj.store==storeId ? accumulator + obj.quantity : accumulator + 0;
			}, 0);
		}
		// broadcast to #bottom_tally
		if (bottom) bottom.innerHTML = tally>0 ? `${tally} items in cart` : null;
	}

	window.addEventListener("cartUpdate", function(event) {
		updateTally();
	});

	onMount(async () => {
		updateTally();
	});
	
</script>







<span>{tally}</span>