// import Widget from './widget.svelte';
import CartControls from './CartControls.svelte';
import Tally from './tally.svelte';
import StoresTally from './stores_tally.svelte';
// import Fulfullments from './fulfullments.svelte';
import PickupOptions from './fulfullments_pickups.svelte';
import DeliveryOptions from './fulfullments_deliveries.svelte';
import Favorite from './favorite.svelte';

window.cart = localStorage.cart ? JSON.parse(localStorage.cart).filter(Boolean) : [];

var STOREID = document.head.querySelector('meta[name="storeID"]');
var STORETOKEN = document.head.querySelector('meta[name="storeToken"]');

const PROPS = {
	storeId: STOREID ? parseInt(STOREID.content) : false,
	storeToken: STORETOKEN.content ? STORETOKEN.content : false
};

// find all products
var products = document.querySelectorAll('aside[data-item-id]');
if (products.length) {
	for (var p of products) {
		new CartControls({ target: p, props: {...PROPS, ...p.dataset} });
	}
}

// cart tally
var cart = document.getElementById('cart');
if (cart) {
	new Tally({ target: cart, props: PROPS });
}

// stores_tally (deploy later)
// var stores_tally = document.getElementById('stores_tally');
// if (stores_tally) {
// 	new StoresTally({ target: stores_tally, props: PROPS });
// }

// find all timeslots
// var timeslots = document.querySelectorAll('*[role="timeslots"]');
// if (timeslots.length) {
// 	for (var t of timeslots) {
// 		new Fulfullments({ target: t, props: t.dataset });
// 	}
// }


var pickups = document.querySelectorAll('*[data-pickups]');
if (pickups.length) {
	for (var t of pickups) {
		new PickupOptions({ target: t, props: t.dataset });
	}
}
var deliveries = document.querySelectorAll('*[data-deliveries]');
if (deliveries.length) {
	for (var t of deliveries) {
		new DeliveryOptions({ target: t, props: t.dataset });
	}
}

// find all favorites (deploy later)
// var favorites = document.querySelectorAll('*[role="favorite"]');
// if (favorites.length) {
// 	for (var f of favorites) {
// 		new Favorite({ target: f, props: f.dataset });
// 	}
// }