<script>
	export let drawerHidden = $$props.drawerHidden;
</script>


<div class="drawer" aria-hidden="{drawerHidden ? 'true' : 'false'}">
	<div tabindex="-1" class="drawer__controls">
		<button class="drawer__button__close" type="button" aria-label="Close modal" on:click={ e => $$props.drawerHidden = drawerHidden = true }>&times; close</button>
	</div>
	<div aria-modal="true" role="dialog" tabindex="-1" class="drawer__content">
		<slot></slot>
	</div>
</div>
<button class="drawer__sheet {!drawerHidden ? 'hidden' : ''}" on:click={ e => $$props.drawerHidden = drawerHidden = true }>Close drawer</button>


<style>
	.drawer {
		background: #fff;
		position: fixed;
		top: 0;
		left: 100%;
		height: 100vh;
		width: 500px;
		box-sizing: border-box;
		z-index: 999999;
		transform: translateX(-100%);
		transition: transform .3s ease;
		padding: var(--gap) calc(var(--gap) * 1.5);
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.drawer[aria-hidden=true] {
		transform: translateX(0);
	}
	.drawer::-webkit-scrollbar {
		-webkit-appearance: none;
		appearance: none;
		display: none;
		width: 0;
	}

	@media (max-width: 500px) {
		.drawer {
			width: 100%;
		}
	}

	.drawer > .drawer__controls {
		text-align: right;
		padding-bottom: var(--gap);
	}

	button.drawer__sheet {
		display: block;
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		left: 100%;
		transform: translateX(-100%);
		background: rgba(111,111,111,.5);
		opacity: 1;
		z-index: 999998;
		border: none;
		overflow: hidden;
		text-indent: -99999999999999rem;
		transition: opacity .3s ease 1ms,transform 1ms ease;
	}
	button.drawer__sheet[aria-hidden=true], button.drawer__sheet:not(.hidden) {
		opacity: 0;
		transform: translateX(0);
		transition: opacity .3s ease,transform 1ms ease .3s;
	}

	button.drawer__button__close {
		font: inherit;
		-webkit-appearance: none;
		appearance: none;
		display: inline-block;
		border: 1px solid var(--greya);
		/* background: #ddd; */
		background: transparent;
		color: var(--grey);
		padding: .4em .8em;
		margin: .2rem calc(var(--gap) / 5) .2rem 0;
		border-radius: 3px;
		cursor: pointer;
		text-transform: uppercase;
		letter-spacing: .015em;
		font-weight: 600;
		font-size: .89rem;
		text-decoration: none;
		align-self: center;
		white-space: nowrap;
		line-height: 120%;
	}
</style>