<script>
	import { onMount } from 'svelte';
	import Drawer from './ui/drawer.svelte'

	
	let pickups = [];
	let drawerHidden = true;

	onMount(async () => {
		var data_pickups = JSON.parse($$props.pickups).filter(Boolean);
		var now = Date.now();
		pickups = data_pickups.map(obj => {
			if (!obj.hasOwnProperty('time_start') || !obj.hasOwnProperty('time_end') || !obj.hasOwnProperty('deadline')) return 0;
			if (obj.deadline<=now) return 0;
			var time_start = new Date(obj.time_start);
			var time_end = new Date(obj.time_end);
			obj.minutes_till_deadline = (obj.deadline - now) / 1000 / 60;
			obj.day = time_start.toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
			obj.timea = time_start.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace(' ', '').toLowerCase();
			obj.timeb = time_end.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace(' ', '').toLowerCase();
			return obj;
		}).filter(Boolean);
	});
	
</script>


{#if pickups.length}
	Next Pick-up 
	<span class="block">{pickups[0].day}</span> 
	<span class="block">{pickups[0].timea} - {pickups[0].timeb}</span> 
	{#if pickups[0].minutes_till_deadline<60}<em class="block" style="color:red">Hurry, only {Math.floor(pickups[0].minutes_till_deadline)} minutes untill order deadline</em> {/if}
	<button class="link" type="button" on:click={ e => drawerHidden = false}>See all options</button>
	<Drawer bind:drawerHidden={drawerHidden}>
		{#if pickups.length}
			<h3 class="timeslots">Pick-Up Options</h3>
			<ul class="timeslots">
				{#each pickups as f}
					<li>{f.day}	<small>{f.timea} - {f.timeb}</small> {#if f.minutes_till_deadline<60}<em style="color:red">Hurry, only {Math.floor(f.minutes_till_deadline)} minutes untill order deadline</em>{/if}</li>
				{/each}
			</ul>
		{/if}
	</Drawer>
{/if}





<style>
	.block {
		display: block;
	}
	h3.timeslots {
		margin: 0;
		padding: 0;
		/* font-weight: 600; */
		font-size: 1.1rem;
		}
	* + h3.timeslots {
		padding-top: .4em;
	}
	ul.timeslots {
		list-style: none;
		margin: 0;
		padding: 0 0 calc(var(--gap) / 3);
		line-height: 120%;
	}
	ul.timeslots li {
		margin: 0;
		padding: 0 0 calc(var(--gap) / 3);
		}
	ul.timeslots small {
		display: block;
		font-size: .85em;
		line-height: 120%;
		color: var(--grey)
	}
</style>